/* < SMALL PHONE >---------------------------------------------------------------------------------------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 350px) and (orientation: portrait) {
    .iosDeviceContainer {
        width: 100% !important;
        height: 70% !important;
        position: relative;
    }
}

/* < MEDIUM PHONE >--------------------------------------------------------------------------------------- */
@media only screen and (min-device-width: 350px) and (max-device-width: 768px) and (orientation: portrait) {
    .iosDeviceContainer {
        width: 100% !important;
        height: 70% !important;
    }
}

/* < IPAD >------------------------------------------------------------------------------------------------ */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .iosDeviceContainer {
        width: 100% !important;
        height: 70% !important;
    }
}

@media only screen and (min-device-height: 768px) and (max-device-height: 1024px) and (orientation: landscape) {
    .iosDeviceContainer {
        width: 100% !important;
        height: 70% !important;
    }
}

/* < IPAD PRO >-------------------------------------------------------------------------------------------- */
@media only screen and (min-device-width: 1024px) and (orientation: portrait) {
    .iosDeviceContainer {
        width: 100% !important;
        height: 70% !important;
    }
}

@media only screen and (min-device-height: 1024px) and (orientation: landscape) {
    .iosDeviceContainer {
        width: 100% !important;
        height: 70% !important;
    }
} 