/* < SMALL PHONE >---------------------------------------------------------------------------------------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 375px) and (orientation: portrait) {
    .iosFooterBox {
        width: 100% !important;
        height: 40% !important;
        max-height: 60% !important;
    }
}

/* < MEDIUM PHONE >--------------------------------------------------------------------------------------- */
@media only screen and (min-device-width: 375px) and (max-device-width: 769px) and (orientation: portrait) {
    .iosFooterBox {
        width: 100% !important;
        max-height: 35% !important;
    }
}

/* < IPAD >------------------------------------------------------------------------------------------------ */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .iosBaseBox {
        flex-direction: column !important;
    }

    .iosFooterBox {
        width: 100% !important;
        height: 25% !important;
    }
}

@media only screen and (min-device-height: 768px) and (max-device-height: 1024px) and (orientation: landscape) {
    .iosBaseBox {
        flex-direction: row-reverse !important;
    }

    .iosFooterBox {
        width: 30% !important;
        height: 100% !important;
    }
}

/* < IPAD PRO >-------------------------------------------------------------------------------------------- */
@media only screen and (min-device-width: 1024px) and (orientation: portrait) {
    .iosBaseBox {
        flex-direction: row-reverse !important;
    }

    .iosFooterBox {
        width: 30% !important;
        height: 100% !important;
    }
}

@media only screen and (min-device-height: 1024px) and (orientation: landscape) {
    .iosBaseBox {
        flex-direction: row-reverse !important;
    }

    .iosFooterBox {
        width: 30% !important;
        height: 100% !important;
    }
} 