.imageTransition {
    opacity: 0;
    animation: imageFadeIn 0.5s linear;
    animation-fill-mode: forwards;
}
  
@keyframes imageFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}