.BrainhubCarousel__dots 
.BrainhubCarousel__dot::before {
    background: #fff;
    width: 12px;
    height: 12px;
}

.BrainhubCarousel__dots 
.BrainhubCarousel__dot.BrainhubCarousel__dot--selected::before {
    background: #3399A2 !important;
}

.BrainhubCarousel__dots 
.BrainhubCarousel__dot {
    opacity: 1 !important;
}