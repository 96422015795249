@font-face {
    font-family: 'proxima-nova';
    font-weight: 700;
    src: url("ProximaNova-Bold.ttf") format("truetype");
}

@font-face {
    font-family: 'proxima-nova';
    src: url("ProximaNova-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: 'proxima-nova';
    src: url("ProximaNova-Reg.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: 'proxima-nova';
    src: url("ProximaNova-Sbold.ttf") format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: 'proxima-nova';
    src: url("ProximaNova-Thin.ttf") format("truetype");
    font-weight: 100;
}

@font-face {
    font-family: 'proxima-nova';
    src: url("ProximaNova-Xbold.ttf") format("truetype");
    font-weight: 800;
}
