.DokaOverlay {
    position: relative;
    overflow: hidden;
}

.DokaOverlay > img {
    display: block;
    width: 100%;
    height: auto;
}

.DokaOverlay > .DokaContainer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.doka--root {
    --doka-color--alpha: #3399A2;
    --doka-color--alpha-dim: #3399A2;
}

.doka--toolbar {
    order: 3;
}
.doka--crop-subject{

    order:1;
}

.doka--crop-rotator{
    order:2;
}
.doka--content {
    margin-top: 1em;
}
